(function($) {
    $.fn._button = function(action) {
      // alert(action);
      if (action === 'loading' && this.data('loading-text')) {
        this.data('original-text', this.html()).html(this.data('loading-text')).prop('disabled', true);
      }
      if (action === 'reset' && this.data('original-text')) {
        this.html(this.data('original-text')).prop('disabled', false);
      }
      if (action === 'success' && this.data('success-text')) {
        this.html(this.data('success-text')).prop('disabled', false);
      }
      if (action === 'error' && this.data('error-text')) {
        this.html(this.data('error-text')).prop('disabled', false);
      }
    };
  }(jQuery));	
  